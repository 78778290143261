<template>
    <button
        class="selection-button-item btn position-relative w-100"
        :class="{ 'active': active }"
        type="button"
        :disabled="disabled">
        <span v-if="active" class="selection-emblem-active rounded-circle bg-success p-1 position-absolute top-0 start-0 mt-1 ms-1 d-inline-flex">
            <Icon symbol="check" class="text-white"></Icon>
        </span>
        <span v-for="emblem in emblems"
            class="badge bg-dark bg-opacity-10 text-dark position-absolute top-0 end-0 me-2 mt-2">
            {{ emblem }}
        </span>
        <span :class="selectionButtonItemClass"><slot></slot></span>
    </button>
</template>

<script lang="ts" setup>
import { PropType, inject } from 'vue';
import { Icon } from '@/modules/core/components';

const selectionButtonItemClass = inject('selectionButtonItemClass', '');

defineProps({
    emblems: {
        type: Array as PropType<Array<string>>,
        default: []
    },
    active: Boolean,
    disabled: Boolean
})
</script>

<style lang="scss">
.selection-button-item {
    border-color: $gray-300;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    
    &.btn {
        box-shadow: none;
    }

    &:hover,
    &:active {
        border-color: $gray-400;
    }

    &.active {
        border-color: $success;
        color: $success;

        .selection-emblem-active {
            visibility: visible;
        }
    }

    @include media-breakpoint-up (lg) {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}
</style>